import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  AllProjects,
  DeleteApplicationInput,
  GetProjectInput,
  ProjectInput,
  ProjectOutput,
  ProjectTypes,
} from '../models/projectDataModel';
import { GeneralProjectDetails } from '../models/homepageModel';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_PRESALES_MAIN_BACKEND,
  credentials: 'same-origin',
  mode: 'cors',
});

export const projectApi = createApi({
  reducerPath: 'projectAPI',
  baseQuery,
  endpoints: (builder) => ({
    getAllProjects: builder.query<AllProjects, string | undefined>({
      query: (token: string) => ({
        url: 'project/getallprojectsdetails',
        method: 'GET',
        headers: {
          jwtToken: token,
          appKey: 'GoSelect',
        },
      }),
    }),
    getProjectById: builder.mutation<ProjectOutput[], GetProjectInput>({
      query: (data: GetProjectInput) => ({
        url: `project/getprojectbyid?projectId=${data.id}`,
        method: 'GET',
        headers: data.userToken
          ? {
              jwtToken: data.userToken,
              appKey: 'GoSelect',
            }
          : {},
      }),
    }),
    createProject: builder.mutation<GeneralProjectDetails, ProjectInput>({
      query: (data: ProjectInput) => ({
        url: 'project/createproject',
        method: 'POST',
        headers: data.userToken
          ? {
              jwtToken: data.userToken,
              appKey: 'GoSelect',
            }
          : { appKey: 'GoSelect' },
        body: data.projectData,
      }),
    }),
    updateProject: builder.mutation<Object, ProjectInput>({
      query: (data: ProjectInput) => ({
        url: 'project/updateproject',
        method: 'POST',
        headers: data.userToken
          ? {
              jwtToken: data.userToken,
              appKey: 'GoSelect',
            }
          : {
              appKey: 'GoSelect',
            },
        body: data.projectData,
      }),
    }),
    deleteApplication: builder.mutation<Object, DeleteApplicationInput>({
      query: (data: DeleteApplicationInput) => ({
        url: `project/deleteapplication?projectId=${data.id}&applicationId=${data.applicationId}`,
        method: 'POST',
        headers: {
          jwtToken: data.userToken,
          appKey: 'GoSelect',
        },
      }),
    }),
    saveProject: builder.mutation<string, GetProjectInput>({
      query: (data: GetProjectInput) => ({
        url: `project/saveproject?projectId=${data.id}&projectName=${data.name}`,
        method: 'POST',
        headers: data.userToken
          ? {
              jwtToken: data.userToken,
              appKey: 'GoSelect',
            }
          : { appKey: 'GoSelect' },
      }),
    }),
    getProjectTypes: builder.query<ProjectTypes, undefined>({
      query: () => ({
        url: 'dimension/getprojecttypes',
        method: 'GET',
      }),
    }),
    getProjectByIDTemp: builder.mutation<ProjectOutput[], any>({
      query: (body: any) => ({
        url: `project/getprojectbyidtemp?projectId=${body.id}`,
        method: 'POST',
        headers: {
          appKey: 'GoSelect',
        },
      }),
    }),
  }),
});

export const {
  useGetAllProjectsQuery,
  useLazyGetAllProjectsQuery,
  useGetProjectByIdMutation,
  useCreateProjectMutation,
  useGetProjectTypesQuery,
  useUpdateProjectMutation,
  useDeleteApplicationMutation,
  useSaveProjectMutation,
  useGetProjectByIDTempMutation,
} = projectApi;
