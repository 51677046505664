import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Blocker } from 'react-router-dom';
import Workspace from '..';
import Button from '../../../components/Form/Button';
import Icon from '../../../components/Icon';
import NarrowMenuContainer from '../../../components/NarrowMenuContainer';
import { NarrowMenuItemProps } from '../../../components/NarrowMenuItem';
import OutsideClickHandler from '../../../components/OutsideClickHandler';
import TabGroup from '../../../components/TabGroup';
import TabItem from '../../../components/TabItem';
import useDeviceDetect from '../../../hooks/useDeviceDetect';
import { useTranslate } from '../../../i18translate/Hooks';
import { I18 } from '../../../languages/I18';
import { RootState } from '../../../store/rootReducer';
import { getSessionStorageValue } from '../../../utils/GeneralUtils';
import DriveConfiguration from '../Configdrive';
import DriveSelection from '../Dimensioning/Components/DriveSelection';
import { MotorSelection } from '../Dimensioning/Components/MotorSelection';
import TransformerSelection from '../Dimensioning/Components/TransformerSelection';
import {
  menuItemData,
  workspaceTabsObject,
} from '../Dimensioning/models/WorkspaceTabModel';
import Efficiency from '../Efficiency/Components/Efficiency';
import Selection from '../Selection/Components/Selection';
import {
  setActiveComponent,
  setActiveTab,
  setApplicationActiveTabs,
  setconfigureDriveTabStatus,
  setCurrentApplication,
  setEfficiencyTabStatus,
} from '../store/WorkspaceReducer';
import CustomTabs from './Tabs';
import { useGetPISDataMutation } from '../../Home/services/APICommonService';
import { useSelectAllMutation } from '../Dimensioning/services/APIDimensioningService';

const WorkspaceTabs = ({ blocker }: { blocker: Blocker }) => {
  const { isMobile } = useDeviceDetect();

  const workspace = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank],
  );

  const sidebarOpen = useSelector(
    (state: RootState) => state.workspace.sidebarOpen,
  );

  const currentAppRank = useSelector(
    (state: RootState) => state.workspace.currentAppRank,
  );

  const dimensionData = useSelector((state: RootState) => {
    return state.workspace.applicationDetails[state.workspace.currentAppRank]
      ?.dimensionDetails;
  });

  const [menuOpen, setMenuOpen] = useState(false);

  const [selectedSubTab, setSelectedSubTab] = useState('MotorInfoTab');
  const [addTabDisabled, setAddTabDisabled] = useState(true);
  const [disableDriveConfigTab, setDisableDriveConfigTab] = useState(false);

  const [catalogueData, catalogStatus] = useGetPISDataMutation({
    fixedCacheKey: 'shared-catalogue-post',
  });

  const [dimension, dimensionResult] = useSelectAllMutation({
    fixedCacheKey: 'shared-dimension-post',
  });

  const dispatch = useDispatch();
  const { t } = useTranslate();

  useEffect(() => {
    setTimeout(() => {
      if (workspace?.configureDriveTab) {
        dispatch(setActiveTab('configurationDrive'));
      } else if (workspace?.efficiencyTab) {
        dispatch(setActiveTab('efficiency'));
      } else {
        dispatch(setActiveTab('selection'));
      }
    }, 100);
  }, [workspace?.applicationActiveTabs, currentAppRank]);

  useEffect(() => {
    const workspace = getSessionStorageValue();

    if (workspace) {
      dispatch(setCurrentApplication(workspace?.currentAppRank));

      if (
        workspace?.applicationDetails?.[workspace?.currentAppRank]
          ?.efficiencyTab
      ) {
        dispatch(setEfficiencyTabStatus(true));
        dispatch(setconfigureDriveTabStatus(false));
      }
      if (
        workspace?.applicationDetails?.[workspace?.currentAppRank]
          ?.configureDriveTab
      ) {
        dispatch(setEfficiencyTabStatus(false));
        dispatch(setconfigureDriveTabStatus(true));
      }
      dispatch(
        setApplicationActiveTabs(
          workspace?.applicationDetails[workspace?.currentAppRank]
            ?.applicationActiveTabs,
        ),
      );
      dispatch(
        setActiveTab(
          workspace?.applicationDetails[workspace?.currentAppRank]?.activeTab,
        ),
      );
    }
  }, [workspace?.configureDriveTab, currentAppRank]);

  useEffect(() => {
    if (
      window?.location?.search?.length > 0 &&
      window.location?.search?.split('&')[1].slice(6)
    ) {
      dispatch(
        setApplicationActiveTabs([workspaceTabsObject['configureDriveTab']]),
      );
      dispatch(setconfigureDriveTabStatus(true));
      dispatch(setEfficiencyTabStatus(false));
      dispatch(setActiveTab('configurationDrive'));
    }
  }, []);

  const closeTab = (data: menuItemData) => {
    const arr = workspace?.applicationActiveTabs?.filter(
      (a) => a?.value !== data?.value,
    );

    dispatch(setApplicationActiveTabs(arr));

    if (data.value === 'efficiency' && arr[0]?.value === 'configurationDrive') {
      dispatch(setEfficiencyTabStatus(false));
      dispatch(setconfigureDriveTabStatus(true));
    } else if (
      data.value === 'configurationDrive' &&
      arr[0]?.value === 'efficiency'
    ) {
      dispatch(setEfficiencyTabStatus(true));
      dispatch(setconfigureDriveTabStatus(false));
    } else {
      dispatch(setEfficiencyTabStatus(false));
      dispatch(setconfigureDriveTabStatus(false));
    }

    dispatch(
      setActiveTab(
        arr.length === 0 ? 'selection' : arr[arr?.length - 1]?.value,
      ),
    );
  };

  useEffect(() => {
    dispatch(setActiveTab('selection'));
    if (workspace?.activeComponent === 'motorDimension') {
      setSelectedSubTab('MotorInfoTab');
    } else {
      setSelectedSubTab('DrivInfoTab');
    }
  }, [workspace?.activeComponent, currentAppRank]);

  useEffect(() => {
    let motor =
      dimensionData?.selectMotorResult?.dimension?.dimensionResult
        ?.supplyDriveLoads?.[0]?.motorUnit?.results;
    let drives =
      dimensionData?.selectDriveResult?.dimension?.dimensionResult
        ?.supplyDriveLoads?.[0]?.inverterUnit?.results;
    let driveTypeCode = '';
    if (drives && drives.length > 0) {
      for (let drive of drives) {
        if (drive.name === 'TypeDesignation') {
          driveTypeCode = drive.value;
        }
      }
    }
    let motorCalId = '';
    if (motor && motor.length > 0) {
      for (let mot of motor) {
        if (mot.name === 'CalcID' || mot.name === 'Calc ID') {
          motorCalId = mot.value;
        }
      }
    }
    if (motorCalId.length !== 0 || driveTypeCode.length !== 0) {
      setAddTabDisabled(false);
    }
    if (motorCalId.length === 0 && driveTypeCode.length === 0) {
      setAddTabDisabled(true);
    }
    if (workspace?.applicationActiveTabs?.length === 2) {
      setAddTabDisabled(true);
    }

    if (!dimensionData?.productTypeCode?.driveTypeCode) {
      setDisableDriveConfigTab(true);
    } else {
      setDisableDriveConfigTab(false);
    }
    setMenuOpen(false);
  }, [
    dimensionData?.selectMotorResult,
    dimensionData?.selectDriveResult,
    dimensionData,
    currentAppRank,
    workspace?.activeComponent,
    disableDriveConfigTab,
    workspace?.applicationActiveTabs,
  ]);

  const onAddTabClick = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    if (catalogStatus.isLoading || dimensionResult.isLoading) {
      setMenuOpen(false);
      setAddTabDisabled(true);
    }
  }, [catalogStatus, dimensionResult]);

  const onMenuClick = async (menuItem: string | number, data: menuItemData) => {
    let active;

    active = workspace?.applicationActiveTabs?.filter((a) => {
      return a?.value === menuItem;
    });

    if (active?.length === 0) {
      dispatch(
        setApplicationActiveTabs([...workspace.applicationActiveTabs, data]),
      );
      setMenuOpen(false);

      if (menuItem === 'efficiency') {
        dispatch(setEfficiencyTabStatus(true));
        dispatch(setconfigureDriveTabStatus(false));
      }
      if (menuItem === 'configurationDrive') {
        dispatch(setEfficiencyTabStatus(false));
        dispatch(setconfigureDriveTabStatus(true));
      }
    }
    dispatch(setActiveTab(menuItem));
  };

  const getNarrowMenuItem = (): NarrowMenuItemProps[] => {
    let menuItems: NarrowMenuItemProps[] = [];

    Object.entries(workspaceTabsObject)?.forEach(([key, data]) => {
      let disable =
        data.value === 'efficiency' &&
        workspace?.applicationActiveTabs?.length > 0
          ? true
          : false;

      menuItems.push({
        label: data.label,
        value: data.value,
        data: data,
        onClick: data.click ? onMenuClick : undefined,
        disabled:
          data.value === 'configurationDrive' && disableDriveConfigTab
            ? true
            : workspace?.activeTab === 'efficiency'
              ? disable
              : workspace?.activeTab === 'configurationDrive' &&
                  data.value === 'configurationDrive'
                ? true
                : data.disabled,
        isClickable: data.click,
      });
    });

    return menuItems;
  };

  const handleOnClickMenuItem = (menuItem: NarrowMenuItemProps) => {
    if (menuItem.onClick) menuItem.onClick(menuItem.value, menuItem.data);
  };

  const mobileLayout = () => {
    return (
      <>
        {isMobile && (
          <div
            className="toggle-button-container"
            data-testid="toggle-button-container"
          >
            <TabGroup
              selectedTabId={selectedSubTab}
              size="small"
              className="result-tabs"
            >
              <TabItem
                label={t(I18.dimensioning_tab_motor_title)}
                closable={false}
                tabId="MotorInfoTab"
                onTabClick={() => {
                  setSelectedSubTab('MotorInfoTab');
                  dispatch(setActiveComponent('motorDimension'));
                }}
              ></TabItem>
              <TabItem
                label={t(I18.dimensioning_tab_drive_title)}
                closable={false}
                tabId="DrivInfoTab"
                onTabClick={() => {
                  setSelectedSubTab('DrivInfoTab');
                  dispatch(setActiveComponent('driveDimension'));
                }}
              ></TabItem>
            </TabGroup>
          </div>
        )}
      </>
    );
  };
  return (
    <div className="workspace-tab">
      {currentAppRank != -1 ? (
        <div className="tab-group">
          <section className="tab-group-container">
            <CustomTabs
              className={`selection-sizing-tab-container ${workspace?.activeTab === 'selection' && 'selected'}`}
              label={t(I18.workspace_tab_selection_dimensioning_title)}
              variant={'primary-tab'}
              closable={false}
              size={'medium'}
              data-rf="selection-tab"
              onClick={() => {
                dispatch(setActiveTab('selection'));
                dispatch(setconfigureDriveTabStatus(false));
                dispatch(setEfficiencyTabStatus(false));
              }}
            >
              {/* <img
              src="selection-dimension-tab.svg"
              alt="Selection and Dimension"
              slot="tab-icon"
              /> */}
            </CustomTabs>

            {workspace?.applicationActiveTabs?.length > 0 &&
              workspace?.applicationActiveTabs?.map((data, index) => {
                if (data?.click) {
                  return (
                    <CustomTabs
                      key={data.value}
                      className={
                        data.value === 'configurationDrive'
                          ? `config-drive-tab-container ${workspace.activeTab === 'configurationDrive' && 'selected'}`
                          : data.value === 'efficiency'
                            ? `efficiency-tab-container ${workspace?.activeTab === 'efficiency' && 'selected'}`
                            : ''
                      }
                      icon={data.icon}
                      label={
                        data.label === 'Configure drive'
                          ? 'Configuration'
                          : data.label
                      }
                      variant={data.variant}
                      closable={
                        data.closable ? data.closable.toString() : undefined
                      }
                      size={'small'}
                      data-rf={data.datarf}
                      onClick={() => {
                        onMenuClick(data.value, data);
                      }}
                      data-testid={`workspacetabs-active-item-${index}`}
                      selected={workspace?.activeTab === data.value}
                    >
                      <Button
                        type="secondary"
                        size="small"
                        onClick={() => closeTab(data)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12 4.76906L11.27 4.03906L8 7.30906L4.73 4.03906L4 4.76906L7.26 8.03906L4 11.3191L4.73 12.0391L8 8.77906L11.27 12.0391L12 11.3191L8.74 8.03906L12 4.76906Z"
                            fill="#1F1F1F"
                          />
                        </svg>
                      </Button>
                    </CustomTabs>
                  );
                }
              })}

            <div
              data-rf="add"
              aria-disabled={addTabDisabled}
              onClick={() => {
                !addTabDisabled && onAddTabClick();
              }}
              id="tab-menu-button"
              data-testid={`workspacetabs-item-plus-icon`}
              className={`${addTabDisabled ? 'pluscode-disable' : 'plus-icon-container'}`}
            >
              <Icon
                slot="icon"
                name="plus"
                theme="dark"
                disabled={addTabDisabled}
              />
            </div>
            <OutsideClickHandler onClickOutside={() => setMenuOpen(false)}>
              <div slot="context-data" className="narrow-menu">
                <NarrowMenuContainer
                  key={menuOpen.toString()}
                  menuItems={getNarrowMenuItem()}
                  onClickMenuItem={handleOnClickMenuItem}
                  isShow={menuOpen}
                ></NarrowMenuContainer>
              </div>
            </OutsideClickHandler>
          </section>

          <div
            id="workspace-tab-container"
            slot="container"
            className="workspace-tab-container"
          >
            <div
              style={
                workspace?.activeTab === 'selection'
                  ? { display: 'block' }
                  : { display: 'none' }
              }
            >
              <div>
                {workspace?.activeComponent === 'workspace' && <Workspace />}
                {workspace?.activeComponent === 'selection' && <Selection />}
                {isMobile &&
                  workspace?.activeComponent !== 'workspace' &&
                  workspace?.activeComponent !== 'selection' &&
                  mobileLayout()}
                {workspace?.activeComponent === 'motorDimension' && (
                  <MotorSelection blocker={blocker} />
                )}
                {workspace?.activeComponent === 'driveDimension' && (
                  <DriveSelection isSidebarOpen={sidebarOpen} />
                )}

                {currentAppRank === -1 && (
                  <TransformerSelection isSidebarOpen={sidebarOpen} />
                )}
              </div>
            </div>
            <div
              className={
                workspace?.activeTab === 'configurationDrive'
                  ? 'unhide-activetab'
                  : ''
              }
            >
              <div>
                {workspace?.activeTab === 'configurationDrive' && (
                  <DriveConfiguration />
                )}
              </div>
            </div>
            <div
              className={
                workspace.activeTab === 'efficiency' ? 'unhide-activetab' : ''
              }
            >
              <Efficiency selectedTabId={workspace.activeTab} />
            </div>
          </div>
        </div>
      ) : (
        <div
          id="workspace-tab-container"
          slot="container"
          className="workspace-tab-container"
        >
          <div>
            {currentAppRank === -1 && (
              <TransformerSelection isSidebarOpen={sidebarOpen} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default WorkspaceTabs;
