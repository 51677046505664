import React from 'react';
import UDPTable from './UDPTable';
import ProductInfoData from './ProductInfoData';
import PDSRelativeLosses from './PDSRelativeLosses';
import UDPLossesChart from '../../Efficiency/Components/UDPLossesChart';
import { PDSLossesChart } from '../../Efficiency/Components/PDSLossesChart';
import StandardAndPredefinedPointsTable from '../../Efficiency/Components/ReportStandardAndPredefinedPointsTable';
import { I18 } from '../../../../languages/I18';
import { useTranslate } from '../../../../i18translate/Hooks';
import { ApplicationDetails } from '../../models/WorkspaceModels';
import LossDetermination from '../LossDetermination';
import { getMinSpeed } from '../../Efficiency/Utils/UdpPointsUnitConversion';

interface ReportEfficiencyProps {
  application: ApplicationDetails;
  index: number;
  segmentIndex: number;
}

const ReportEfficiency = ({
  application,
  index,
  segmentIndex,
}: ReportEfficiencyProps) => {
  const { t } = useTranslate();
  //pds details,pds relative chart and standard point in one page

  return (
    <div className="report-efficiency-container">
      <div id={`${application.id}-efficiency`}>
        <div id="pdf-break-before">
          <div className="efficiency-report-header segment-contentheader">
            3.{index}.{segmentIndex} Efficiency Report
          </div>
          <ProductInfoData
            pdsEfficiencyDetails={application?.pdsEfficiencyDetails}
          />
          <PDSRelativeLosses
            psdEfficientDetails={application?.pdsEfficiencyDetails}
          />
          {application?.pdsEfficiencyDetails &&
            Object.keys(application?.pdsEfficiencyDetails).length > 0 && (
              <>
                <div id="pdf-section">
                  <div className="efficiency-page-headers">
                    {t(I18.efficiency_standard_points_header)}
                  </div>
                  <StandardAndPredefinedPointsTable
                    data={application?.pdsEfficiencyDetails.standardPoints}
                  />
                  <div className="efficiency-page-table-msg">
                    {t(I18.efficiency_operating_points_table_regulation)}
                  </div>
                </div>
              </>
            )}
        </div>
        <div id="pdf-break-before">
          {application?.pdsEfficiencyDetails &&
            Object.keys(application?.pdsEfficiencyDetails).length > 0 && (
              <>
                <div className="efficiency-page-headers">
                  {t(I18.pds_absolute_losses_heading)}
                </div>

                <PDSLossesChart
                  height={220}
                  loading={false}
                  resultsLoading={false}
                  type={'large'}
                  standardPoints={
                    application?.pdsEfficiencyDetails?.standardPoints
                  }
                  id={application.id}
                  fZero={
                    application?.pdsEfficiencyDetails.pdsDriveDetails.fZero
                  }
                />
              </>
            )}{' '}
          {application?.efficiencyUDPLossesdata &&
            application?.efficiencyUDPLossesdata?.length > 0 && (
              <UDPTable
                applicationUdpPoints={application?.efficiencyUDPLossesdata}
              />
            )}
          <div id="pdf-section">
            {application?.efficiencyUDPLossesdata &&
              application?.efficiencyUDPLossesdata?.length > 0 && (
                <UDPLossesChart
                  id={application?.id}
                  height={220}
                  loading={false}
                  type={'large'}
                  UdpData={{
                    minSpeedPercentage: getMinSpeed(
                      application?.dimensionDetails?.motorLoadData,
                      application?.dimensionDetails?.selectMotorResult
                        ?.dimension?.dimensionResult,
                    ),
                    maxSpeedPercentage:
                      application?.dimensionDetails?.motorLoadData.loadType ===
                      '1'
                        ? 100
                        : 200,
                    minTorquePercentage: 15,
                    maxTorquePercentage:
                      parseInt(
                        application?.dimensionDetails?.dimensionInputData
                          ?.selectionInputs?.[0]?.olBaseInput ?? '150',
                      ) > 150
                        ? 150
                        : parseInt(
                            application?.dimensionDetails?.dimensionInputData
                              .selectionInputs?.[0]?.olBaseInput ?? '150',
                          ),

                    speedUnit: '%',
                    torqueUnit: '%',
                    torqueOrPower: 'torque',
                  }} // min values min speed and min torque
                  partloadPoints={application?.efficiencyUDPLossesdata}
                />
              )}
          </div>
        </div>
      </div>
      <LossDetermination application={application} />
    </div>
  );
};

export default ReportEfficiency;
