import './style/style.scss';

import HandleDriveInputReqeust from './components/LVDriveFormRequest';
import DriveResults from './components/DriveResults';
import ConfigureDriveInput from './components/ConfigureDriveInput';
import { v4 as uuidv4 } from 'uuid';
import { useSession } from '../../../utils/Auth';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/rootReducer';
import { setSessionStorageValue } from '../../../utils/GeneralUtils';

const DriveConfiguration = () => {
  const requestid = uuidv4();
  const { session, loading } = useSession();

  const application = useSelector(
    (state: RootState) =>
      state.workspace?.applicationDetails?.[state?.workspace?.currentAppRank],
  );

  const state = useSelector((state: RootState) => state);

  const handleSubmit = () => {
    const XMLValue = `<?xml version="1.0" encoding="UTF-8" standalone="no"?><configurationRequest>
    <header>
      <session>
        <version>2.0</version>
        <actions>
          <action>edit</action>
        </actions>
        <date>19082024</date>
        <time>07:53:26</time>
        <responseType>configuration</responseType>
        <returnURL>${process.env.REACT_APP_OIDC_CLIENT_ROOT === 'http://localhost:3000' ? `https://dev.goselect.motion.abb.com` : `${process.env.REACT_APP_OIDC_CLIENT_ROOT}`}/workspace?projectId=${window.sessionStorage.getItem('id')}&appId=${application?.id}</returnURL>
        <launchPage>${process.env.REACT_APP_OIDC_CLIENT_ROOT === 'http://localhost:3000' ? `https://dev.goselect.motion.abb.com` : `${process.env.REACT_APP_OIDC_CLIENT_ROOT}`}/workspace</launchPage>
        <RequestID>${requestid}</RequestID>
        <SenderID>GOSELECT</SenderID>
        <SenderKey>GOSELECT</SenderKey>
        <ReceiverID>ABB.ATDP.SEL.FI</ReceiverID>
      </session>
      ${
        !session
          ? `<user>
        <userId>anna.drenda@pl.abb.com</userId>
        <userLevel>external</userLevel>
      </user>`
          : `<user>
        <userId>${session?.user?.profile?.email}</userId>
        <userLevel>${session?.user?.profile?.email?.includes('abb.com') ? 'internal' : 'external'}</userLevel>
      </user>`
      }
      <quotation>
        <QuotationID/>
        <customerIds>
          <customerId domain="SPAINTEST" system="OMS">10105005 </customerId>
        </customerIds>
        <customerCountry>ES</customerCountry>
        <hostName>SUESMOT</hostName>
        <requestedLanguage>en-GB</requestedLanguage>
        <requestedLanguageThreeLetterISO>eng</requestedLanguageThreeLetterISO>
      </quotation>
    </header>
    <items>
      <item>
        <quantity>1</quantity>
        <GlobalCommercialAlias>${
          application?.dimensionDetails?.productTypeCode?.driveTypeCode
        }</GlobalCommercialAlias>
      </item>
    </items>
  </configurationRequest>
      `;
    document.getElementById('CONFIGURATIONREQUEST')?.setHTMLUnsafe(XMLValue);
    const form = document.getElementById('myForm');

    if (form instanceof HTMLFormElement) {
      setSessionStorageValue(state.workspace);
      form.submit();
    } else {
      console.error('Element with id "myForm" is not a form');
    }
  };

  return (
    <>
      <div
        className="drive-configurator-container drive-configurator-dual-layout"
        id="drive-configurator-container"
      >
        <ConfigureDriveInput handleDriveInputReqeust={handleSubmit} />
        <DriveResults />
      </div>

      <HandleDriveInputReqeust />
    </>
  );
};

export default DriveConfiguration;
