import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/rootReducer';
import Icon from '../../../../components/Icon';
import Card from '../../../../components/Card';
import driveIcon from '../../../../assets/images/drive-noimage.jpg';

interface ConfigureDriveInputProps {
  handleDriveInputReqeust: () => void;
}

const ConfigureDriveInput = ({
  handleDriveInputReqeust,
}: ConfigureDriveInputProps) => {
  const application = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank],
  );

  return (
    <>
      <div className="drive-config-input">
        <div className="selection-header">
          <Icon name="drives" size="medium" theme="dark" slot="icon"></Icon>
          <h5 className="selection-title">Drive Configuration</h5>
        </div>
        <Card
          key={'drive-config'}
          onCardClick={() => {
            handleDriveInputReqeust();
          }}
          size="default"
          type="default"
          className={
            application?.drivePlusCodeObject?.options &&
            application?.drivePlusCodeObject?.options?.length > 0
              ? 'drive-config-input-card-reconfigure'
              : 'drive-config-input-card'
          }
          data-testid="drive-config-card"
        >
          <div slot="contents" className="drive-config-card-content">
            <div className="drive-config-card-icon-and-text">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
              >
                <path
                  d="M7 40H5V3L6 2H28L29 3V22H27V4H7V40Z"
                  fill={`${
                    application?.drivePlusCodeObject?.options &&
                    application?.drivePlusCodeObject?.options?.length > 0
                      ? '#1F1F1F'
                      : '#fff'
                  }`}
                />
                <path
                  d="M28.0391 43.02L8.03906 43C6.38906 43 5.03906 41.65 5.03906 40H7.03906C7.03906 40.55 7.48906 41 8.03906 41L28.0391 41.02V43.02Z"
                  fill={`${
                    application?.drivePlusCodeObject?.options &&
                    application?.drivePlusCodeObject?.options?.length > 0
                      ? '#1F1F1F'
                      : '#fff'
                  }`}
                />
                <path
                  d="M24 27H10L9 26V7L10 6H24L25 7V26L24 27ZM11 25H23V8H11V25Z"
                  fill={`${
                    application?.drivePlusCodeObject?.options &&
                    application?.drivePlusCodeObject?.options?.length > 0
                      ? '#1F1F1F'
                      : '#fff'
                  }`}
                />
                <path
                  d="M15 20H13V22H15V20Z"
                  fill={`${
                    application?.drivePlusCodeObject?.options &&
                    application?.drivePlusCodeObject?.options?.length > 0
                      ? '#1F1F1F'
                      : '#fff'
                  }`}
                />
                <path
                  d="M21 20H19V22H21V20Z"
                  fill={`${
                    application?.drivePlusCodeObject?.options &&
                    application?.drivePlusCodeObject?.options?.length > 0
                      ? '#1F1F1F'
                      : '#fff'
                  }`}
                />
                <path
                  d="M18 18H16V20H18V18Z"
                  fill={`${
                    application?.drivePlusCodeObject?.options &&
                    application?.drivePlusCodeObject?.options?.length > 0
                      ? '#1F1F1F'
                      : '#fff'
                  }`}
                />
                <path
                  d="M18 22H16V24H18V22Z"
                  fill={`${
                    application?.drivePlusCodeObject?.options &&
                    application?.drivePlusCodeObject?.options?.length > 0
                      ? '#1F1F1F'
                      : '#fff'
                  }`}
                />
                <path
                  d="M24 15H10V17H24V15Z"
                  fill={`${
                    application?.drivePlusCodeObject?.options &&
                    application?.drivePlusCodeObject?.options?.length > 0
                      ? '#1F1F1F'
                      : '#fff'
                  }`}
                />
                <path
                  d="M16.7598 38.9698L8.75977 36.9698L9.23977 35.0298L16.9598 36.9598L22.7298 35.0598L23.3498 36.9598L17.3098 38.9498L16.7598 38.9698Z"
                  fill={`${
                    application?.drivePlusCodeObject?.options &&
                    application?.drivePlusCodeObject?.options?.length > 0
                      ? '#1F1F1F'
                      : '#fff'
                  }`}
                />
                <path
                  d="M26.0508 46.02H8.05078L7.05078 45.02V42.02H9.05078V44.02H25.0508V42.02H27.0508V45.02L26.0508 46.02Z"
                  fill={`${
                    application?.drivePlusCodeObject?.options &&
                    application?.drivePlusCodeObject?.options?.length > 0
                      ? '#1F1F1F'
                      : '#fff'
                  }`}
                />
                <path
                  d="M39.62 27.83L41.08 26.39L39.66 25L38.21 26.44C37.27 25.72 36.17 25.25 35 25.07V23H33V25.07C31.83 25.24 30.73 25.7 29.79 26.41L28.35 25L26.93 26.42L28.38 27.86C27.69 28.78 27.24 29.86 27.07 31H25V33H27.07C27.24 34.17 27.7 35.27 28.41 36.21L26.93 37.69L28.35 39.11L29.83 37.62C30.76 38.31 31.85 38.76 33 38.93V41H35V38.93C36.16 38.76 37.25 38.3 38.19 37.6L39.65 39.07L41.07 37.65L39.6 36.19C40.3 35.25 40.76 34.16 40.93 33H43V31H40.93C40.76 29.85 40.31 28.76 39.62 27.83ZM34 37C31.24 37 29 34.76 29 32C29 29.24 31.24 27 34 27C36.76 27 39 29.24 39 32C39 34.76 36.76 37 34 37Z"
                  fill={`${
                    application?.drivePlusCodeObject?.options &&
                    application?.drivePlusCodeObject?.options?.length > 0
                      ? '#1F1F1F'
                      : '#fff'
                  }`}
                />
              </svg>
              {/* <img src="./drive_config.svg" alt="drive-config-image" /> */}
              <div>
                <h5>
                  {application?.drivePlusCodeObject?.options &&
                  application?.drivePlusCodeObject?.options?.length > 0
                    ? 'Reconfigure drive'
                    : 'Configure drive'}
                </h5>
                <p>Open LV Drive Configurator tool to configure this drive.</p>
              </div>
            </div>
            <Icon
              name="open-in-new-window"
              size="medium"
              theme={
                application?.drivePlusCodeObject?.options &&
                application?.drivePlusCodeObject?.options?.length > 0
                  ? 'dark'
                  : 'light'
              }
            />
          </div>
        </Card>
        <div className="drive-config-input-footer">
          <h6>Selected ABB drive</h6>

          <div>
            <p>type code</p>
            <h4 data-testid="longtypecode">
              {application?.dimensionDetails?.productTypeCode?.driveTypeCode}
            </h4>
          </div>

          <div className="drive-config-image-container img-and-desc-container">
            <div className="img-container">
              <img
                src={
                  application.dimensionDetails?.selectDriveResult?.dimension
                    ?.catalogueDataDrive?.productImages &&
                  application.dimensionDetails?.selectDriveResult?.dimension
                    ?.catalogueDataDrive?.productImages.length > 0
                    ? application.dimensionDetails?.selectDriveResult?.dimension
                        ?.catalogueDataDrive?.productImages?.[0]?.thumbnailUrl
                    : driveIcon
                }
                alt={
                  application.dimensionDetails?.selectDriveResult?.dimension
                    ?.catalogueDataDrive?.productImages &&
                  application.dimensionDetails?.selectDriveResult?.dimension
                    ?.catalogueDataDrive?.productImages.length > 0
                    ? application.dimensionDetails?.selectDriveResult?.dimension
                        ?.catalogueDataDrive?.productImages?.[0]?.thumbnailUrl
                    : ''
                }
              />
            </div>

            <div className="desc-container">
              {
                application?.dimensionDetails?.selectDriveResult?.dimension
                  ?.catalogueDataDrive?.productAttributes?.CatalogDescription
                  ?.values[0]?.text
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfigureDriveInput;

//ACS580-01-246A-4
//when motor got updated need to check about the drive also
//also i need to close the tab
