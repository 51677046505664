import { useEffect, useState } from 'react';
import DualSectionLayout from '../../../../layout/components/DualSectionLayout';
import ButtonGroup from '../../../../components/Form/ButtonGroup';
import useDeviceDetect from '../../../../hooks/useDeviceDetect';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectDrive,
  dimensionOutputData,
  dimensionDataLoading,
  recommendedDriveList,
  productAndTypeCodeSave,
  selectMotor,
  saveDriveErrorResponse,
  resetDriveErrorResponse,
  saveMotorErrorResponse,
  dimensionMotorInputData,
  recommendedMotorList,
  resetMotorErrorResponse,
  setHasRetryCatalogDataSelected,
  updateProductTypeDriveCode,
  setPlusCodeOptionValues,
  setApplicationActiveTabs,
} from '../../store/WorkspaceReducer';
import DriveSelectionResult from './DriveSelectionResult';
import ButtonGroupMobile from '../../../../components/Form/ButtonGroupMobile';
import {
  defaultDriveFormData,
  defaultMachineryDriveFormData,
  driveSelectionAllDataDefault,
} from '../models/DriveDefaultValues';
import DriveSelectionForm from '../Components/DriveSelectionForm';
import { I18 } from '../../../../languages/I18';
import { useTranslate } from '../../../../i18translate/Hooks';
import {
  useRecommendedDriveDataMutation,
  useSelectAllMutation,
} from '../services/APIDimensioningService';
import { RootState } from '../../../../store/rootReducer';
import {
  Dimension,
  SelectionInput,
  LoadCycle,
} from '../models/MotorSelectionModels';
import notificationManager from '../../../../utils/NotificationManager';
import { useGetPISDataMutation } from '../../../Home/services/APICommonService';
import { useSession } from '../../../../utils/Auth';
import { useUpdateProjectMutation } from '../../../Home/services/APIMainService';
import { motorFormDataDefaults } from '../models/MotorDefaultValues';
import {
  DimensionInput,
  TransformerDimensionProjectIdData,
} from '../models/TransformerSelectionModel';
import { appInsights } from '../../../../services/AppInsight';
import { usePostPlusCodeDataByIdMutation } from '../../services/DrivePluscodeService';

interface DriveSelectionProps {
  isSidebarOpen: boolean;
}
const DriveSelection = ({ isSidebarOpen }: DriveSelectionProps) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const { session } = useSession();
  const { isMobile } = useDeviceDetect();
  const [selectedOption, setSelectedOption] = useState('');
  const [isSelectDriveClicked, setSelectDriveClicked] = useState(false);
  const [dimensioningData] = useSelectAllMutation({
    fixedCacheKey: 'shared-dimension-post',
  });
  const [updateProject] = useUpdateProjectMutation({
    fixedCacheKey: 'shared-update-post',
  });
  const [catalogueData, status] = useGetPISDataMutation({
    fixedCacheKey: 'shared-catalogue-post',
  });
  const [updatePlusCode] = usePostPlusCodeDataByIdMutation({
    fixedCacheKey: 'shared-update-post',
  });
  useEffect(() => {
    appInsights.trackPageView({
      name: 'Drive selection page',
      pageType: 'Sub page',
    });
  }, []);

  const [viewResult, setViewResult] = useState(false);

  const selectedTransformerDatas = useSelector(
    (state: RootState) =>
      state.workspace.transformerDetails.selectedTransformerDatas
        ?.transformerInput,
  );
  const selectedFormData = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.driveFormData,
  );
  const selectedLoadData = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.driveLoadData,
  );
  const [isManualSelectionLoading, setIsManualSelectionLoading] =
    useState(false);
  const [viewManualSelection, setViewManualSelection] = useState(false);

  const projectMotorType = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.typeIds,
  );

  const ambientConditionValues = useSelector(
    (state: RootState) => state.workspace.ambientConditionValues,
  );

  const applicationDetails = useSelector(
    (state: RootState) =>
      state?.workspace?.applicationDetails[state.workspace.currentAppRank],
  );

  const motorResultData = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails?.selectMotorResult,
  );

  const motorFormData = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails?.motorFormData,
  );
  const motorLoadData = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails?.motorLoadData,
  );
  const driveResultData = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails?.selectDriveResult,
  );

  const projectDetails = useSelector(
    (state: RootState) => state.home.projectData,
  );

  const workspace = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank],
  );

  const projectId = window.sessionStorage.getItem('id');
  const [isLoading, setIsLoading] = useState(false);
  const [isGetDimensionLoading, setIsGetDimensionLoading] = useState(false);
  const [dimensioningDataResult, setDimensioningDataResult] =
    useState<Dimension>();
  const [hasCatalogueDataFetched, setHasCatalogueDataFetched] = useState(false);
  const [manualSelectionDriveData] = useRecommendedDriveDataMutation();
  const [currentDimensionInput, setCurrentDimensionInput] = useState<
    SelectionInput | undefined
  >(undefined);
  const manualSelectionData = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.manualSelectionDriveData,
  );
  const dutyCycleDriveValues = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.dutyCycleDriveValues,
  );
  const updatedFormFields = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.driveFormData,
  );
  const dutyCycleMotorValues = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.dutyCycleMotorValues,
  );
  const hasDimensionDataFetched = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.hasDimensionDataFetched,
  );

  const hasRetryCatalogDataSelected = useSelector(
    (state: RootState) => state.workspace.hasRetryCatalogDataSelected,
  );

  const handleSelectDriveButtonClick = (optionSelected: string) => {
    if (isMobile) {
      setSelectedOption(optionSelected);
    }
    setSelectDriveClicked(true);
  };

  const handleBackButtonClick = () => {
    setViewResult(false);
  };

  const handleManualSelection = () => {
    setViewManualSelection(true);
  };

  useEffect(() => {
    if (dimensioningDataResult || driveResultData) {
      if (!dimensioningDataResult) {
        setDimensioningDataResult(driveResultData);
        setCurrentDimensionInput(
          driveResultData?.dimension?.dimensionInput?.selectionInputs[0],
        );
      }
      if (
        currentDimensionInput &&
        viewManualSelection &&
        !manualSelectionData
      ) {
        setIsManualSelectionLoading(true);

        manualSelectionDriveData({
          selectionInputs:
            driveResultData?.dimension?.dimensionInput?.selectionInputs,
          transformerInput: selectedTransformerDatas,
        })
          .then((response) => {
            if (
              response.data &&
              response?.data?.drivesList?.recommendedDrives !== null
            ) {
              dispatch(recommendedDriveList(response?.data));
              setIsManualSelectionLoading(false);
            } else {
              setIsManualSelectionLoading(false);
              notificationManager.error(
                'Unable to retrieve manual selection data',
              );
            }
          })
          .catch(() => {
            setIsManualSelectionLoading(false);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dimensioningDataResult,
    dispatch,
    currentDimensionInput,
    viewManualSelection,
  ]);

  const updateProjectApiCall = (response: Dimension) => {
    updateProject({
      userToken: session ? session?.user?.access_token : undefined,
      projectData: {
        projectId:
          (projectDetails.projectId || window.sessionStorage.getItem('id')) ??
          '',
        projectName:
          (projectDetails.projectName ||
            window.sessionStorage.getItem('name')) ??
          '',
        jsonFileName: workspace.id,
        projectJson: JSON.stringify({
          dimensionSavedData: {
            dimensionInput:
              response?.dimension?.dimensionInput?.selectionInputs?.[0],
            applicationOutput: {
              supplyDriveLoads:
                response?.dimension?.dimensionResult?.supplyDriveLoads,
            },
            exceptionType: response?.exceptionType,
            message: response?.message,
          },
          selectionSavedData: {
            industryName: workspace?.selectionDetails?.industryName,
            applicationName: workspace?.selectionDetails?.applicationName,
          },
          //set the longtypecodevalues.
          plusOptions:
            response?.dimension?.dimensionResult?.supplyDriveLoads?.[0]
              ?.inverterUnit?.results?.[0]?.value ===
            workspace?.drivePlusCodeObject?.longtypeCode?.split('+')?.[0]
              ? {
                  longtypeCode:
                    response?.dimension?.dimensionResult?.supplyDriveLoads?.[0]
                      ?.inverterUnit?.results?.[0]?.value,
                  options: workspace?.drivePlusCodeObject?.options,
                }
              : null,
        }),
        transformerId: selectedTransformerDatas?.trafoId,
        applicationId: workspace?.id,
        bomDetailsJson: JSON.stringify({
          AppId: workspace?.id,
          MotorId:
            response?.dimension?.dimensionResult?.supplyDriveLoads?.[0]
              .motorUnit?.results?.[1]?.value,
          MotorTypeCode:
            response?.dimension?.dimensionResult?.supplyDriveLoads?.[0]
              .motorUnit.results?.[0]?.value,
          MotorName:
            response?.dimension?.dimensionInput?.selectionInputs?.[0].motorName,
          MotorCount:
            response?.dimension?.dimensionInput?.selectionInputs?.[0]
              .motorsCount,
          DriveId:
            response?.dimension.catalogueDataDrive?.productAttributes?.ProductId
              ?.values?.[0]?.text,
          DriveTypeCode:
            response?.dimension?.dimensionResult?.supplyDriveLoads?.[0]
              .inverterUnit.results?.[0]?.value ?? '',
          DriveName:
            response?.dimension?.dimensionInput?.selectionInputs?.[0]
              .driveName ?? '',
          DriveCount:
            response?.dimension?.dimensionInput?.selectionInputs?.[0]
              .drivesCount,
        }),
      },
    });
  };

  const handleSelectButtonClick = (
    type: string,
    manuallySelectedIndex?: string,
  ) => {
    setIsLoading(true);
    dispatch(recommendedDriveList(undefined));
    setHasCatalogueDataFetched(false);
    dispatch(recommendedMotorList(undefined));
    dispatch(resetDriveErrorResponse());
    let drivePlsuCode =
      workspace?.drivePlusCodeObject?.longtypeCode?.split('+')?.[0];
    let dimensionInputData: SelectionInput = {
      appId: applicationDetails.id,
      Compute: 'true',
      dataCategory: type,
      driveName: selectedLoadData?.driveName ?? '',
      projectType:
        workspace.drive?.driveGroup ??
        projectMotorType?.projectType?.toString(),
      loadType: selectedLoadData?.loadType ?? '',
      overLoadType: selectedLoadData?.overLoadType ?? '',
      iContinuous: type === 'Drive' ? selectedLoadData?.iContinuous : undefined,
      iMax: type === 'Drive' ? selectedLoadData?.iMax : undefined,
      maxLoadTime:
        motorLoadData.maxLoadTime ??
        driveSelectionAllDataDefault.driveLoadData.maxLoadTime,
      dutyCycleTime:
        driveResultData?.dimension?.dimensionInput?.selectionInputs?.[0]
          ?.dutyCycleTime ??
        driveSelectionAllDataDefault.driveLoadData.dutyCycleTime,
      power: type === 'Drive' ? '0' : motorLoadData?.power,
      drivesCount: selectedLoadData?.drivesCount ?? '1',
      motorType: motorFormData?.motorType,
      olBaseInput: motorLoadData?.olBaseInput,
      maxSpeed: motorLoadData?.maxSpeed,
      olMaxInput:
        type === 'Drive' ? undefined : (motorLoadData?.olMaxInput ?? '100'),
      nMin: motorLoadData?.nMin,
      nBase: motorLoadData?.nBase,
      nMax: motorLoadData?.nMax,
      motorName: motorFormData?.motorName,
      motorsCount: motorFormData?.motorsCount ?? undefined,
    };

    dimensionInputData = {
      ...dimensionInputData,
      motorsCount:
        motorFormData?.motorsCount ?? motorFormDataDefaults.motorsCount,
      meps: motorFormData?.meps ?? motorFormDataDefaults.meps,
      marineClassification:
        motorFormData?.marineClassification ??
        motorFormDataDefaults.marineClassification,
      frameMaterial:
        motorFormData?.frameMaterial ?? motorFormDataDefaults.frameMaterial,
      motorFamily:
        motorFormData?.motorFamily ?? motorFormDataDefaults.motorFamily,
      poleNumber: motorFormData?.poleNumber ?? motorFormDataDefaults.poleNumber,
      terminalBoxLocation:
        motorFormData?.terminalBoxLocation ??
        motorFormDataDefaults.terminalBoxLocation,
      efficiencyClass:
        motorFormData?.efficiencyClass ?? motorFormDataDefaults.efficiencyClass,
      winding: motorFormData?.winding ?? motorFormDataDefaults.winding,
      design: motorFormData?.design ?? motorFormDataDefaults.design,
      connection: motorFormData?.connection ?? motorFormDataDefaults.connection,
      ipClass: motorFormData?.ipClass ?? motorFormDataDefaults.ipClass,
      icClass: motorFormData?.icClass ?? motorFormDataDefaults.icClass,
      imClass: motorFormData?.imClass ?? motorFormDataDefaults.imClass,
      maxSpeedRule:
        motorFormData?.maxSpeedRule ?? motorFormDataDefaults.maxSpeedRule,
      insulation: motorFormData?.insulation ?? motorFormDataDefaults.insulation,
      tempRiseClass:
        motorFormData?.tempRiseClass ?? motorFormDataDefaults.tempRiseClass,
      tempReserve:
        motorFormData?.tempReserve ?? motorFormDataDefaults.tempReserve,
      motorTmaxMargin:
        motorFormData?.motorTmaxMargin ?? motorFormDataDefaults.motorTmaxMargin,
    };

    if (selectedFormData?.construction) {
      if (selectedFormData?.type !== '0') {
        dimensionInputData = {
          ...dimensionInputData,
          type: selectedFormData?.type,
          driveIPclass: selectedFormData?.ipClass,
          switchFreq: selectedFormData?.minSwitchFreq,
          construction: selectedFormData?.construction,
        };
      } else if (selectedFormData?.type === '0') {
        dimensionInputData = {
          ...dimensionInputData,
          type: selectedFormData?.type,
        };
      }
    } else {
      if (projectMotorType?.projectType.toString() === '7') {
        dimensionInputData = {
          ...dimensionInputData,
          type: defaultMachineryDriveFormData?.type,
        };
      } else {
        dimensionInputData = {
          ...dimensionInputData,
          type: defaultDriveFormData?.type,
        };
      }
    }
    if (updatedFormFields?.type) {
      if (updatedFormFields && updatedFormFields?.type !== '20') {
        dimensionInputData = {
          ...dimensionInputData,
          type: updatedFormFields?.type,
          driveIPclass: updatedFormFields?.ipClass,
          switchFreq: updatedFormFields?.minSwitchFreq,
          construction: updatedFormFields?.construction,
        };
      } else if (updatedFormFields && updatedFormFields?.type === '20') {
        dimensionInputData = {
          ...dimensionInputData,
          type: updatedFormFields?.type,
        };
      }
    }

    if (manuallySelectedIndex !== '') {
      dimensionInputData = {
        ...dimensionInputData,
        driveIndex: manuallySelectedIndex,
      };
    }
    let driveLoadCycles: LoadCycle[] = [];
    if (dutyCycleDriveValues.length > 0) {
      dimensionInputData = {
        ...dimensionInputData,
        driveLoadCycles: null,
      };
      dutyCycleDriveValues?.forEach((row, index) => {
        driveLoadCycles = [
          ...driveLoadCycles,
          {
            rowNum: index.toString(),
            time: row.time.toString(),
            minSpeed:
              dutyCycleMotorValues?.[index]?.minSpeed?.toString() ?? '0',
            maxSpeed:
              dutyCycleMotorValues?.[index]?.maxSpeed?.toString() ?? '0',
            load: dutyCycleMotorValues?.[index]?.load?.toString() ?? '0',
            cosfi: row.cosfi.toString(),
            fMax: row.maxF.toString(),
            fMin: row.minF.toString(),
            isCosFiUpdatedByUser: 'true',
            isCurrentUpdatedByUser: 'true',
            isFmaxUpdatedByUser: 'true',
            isFminUpdatedByUser: 'true',
          },
        ];
      });
      dimensionInputData = {
        ...dimensionInputData,
        driveLoadCycles: driveLoadCycles,
      };

      let motorLoadCycles: LoadCycle[] = [];
      if (dutyCycleMotorValues && dimensionInputData?.overLoadType === '1') {
        dimensionInputData = {
          ...dimensionInputData,
          motorLoadCycles: null,
        };
        dutyCycleMotorValues?.forEach((row, index) => {
          motorLoadCycles = [
            ...motorLoadCycles,
            {
              rowNum: index.toString(),
              time: row.time.toString(),
              minSpeed: row.minSpeed.toString(),
              maxSpeed: row.maxSpeed.toString(),
              load: row.load.toString(),
              cosfi: dutyCycleDriveValues?.[0]?.cosfi?.toString() ?? '0',
              fMax: dutyCycleDriveValues?.[0]?.maxF?.toString() ?? '0',
              fMin: dutyCycleDriveValues?.[0]?.minF?.toString() ?? '0',
              isCosFiUpdatedByUser: 'true',
              isCurrentUpdatedByUser: 'true',
              isFmaxUpdatedByUser: 'true',
              isFminUpdatedByUser: 'true',
            },
          ];
        });
        dimensionInputData = {
          ...dimensionInputData,
          motorLoadCycles: motorLoadCycles,
        };
      }
    }

    dispatch(dimensionDataLoading(true));
    setIsGetDimensionLoading(true);

    let transformerDimensionInput: DimensionInput = {
      transformerInput: selectedTransformerDatas,
      selectionInputs: [
        {
          ...dimensionInputData,
          cableLength: updatedFormFields?.cableLength,
          cableSize: updatedFormFields?.cableSize,
          choke: updatedFormFields?.chokes,
          construction: updatedFormFields?.construction,
          coolingType: updatedFormFields?.cooling,
          eMC: updatedFormFields?.emcCategory,
          glycolconcentration: updatedFormFields?.glycolConcentration,
          gridVoltage: updatedFormFields?.gridVoltage,
          ipClass: motorFormData?.ipClass,
          liquidtemp: updatedFormFields?.liquidTemperature,
          switchFreq: updatedFormFields?.minSwitchFreq,
          driveName: selectedLoadData?.driveName,
          pulse: updatedFormFields?.pulse,
          sineFilter: updatedFormFields?.sineFilter,
          variant: updatedFormFields?.variant,
          drivesCount: selectedLoadData?.drivesCount,
          type: updatedFormFields?.type,
        },
      ],
      ambientInputs: {
        ambientAltitude: ambientConditionValues?.altitude,
        ambientTempInverter: ambientConditionValues?.driveTemparature,
        ambientTempMotor: ambientConditionValues?.motorTemparature,
      },
    };

    setCurrentDimensionInput(dimensionInputData);

    let transformerDimensionProjectData: TransformerDimensionProjectIdData = {
      transformerDimension: transformerDimensionInput,
      projectId: projectId ?? '',
    };

    dimensioningData(transformerDimensionProjectData)
      .then((response) => {
        if (response.data) {
          const transformerDimensionInput: DimensionInput = {
            selectionInputs: [
              {
                ...dimensionInputData,
                power: motorLoadData.power,
                olMaxInput: motorLoadData.olMaxInput,
              },
            ],
            transformerInput: selectedTransformerDatas,
            ambientInputs: {
              ambientAltitude: ambientConditionValues?.altitude,
              ambientTempInverter: ambientConditionValues?.driveTemparature,
              ambientTempMotor: ambientConditionValues?.motorTemparature,
            },
          };
          dispatch(dimensionMotorInputData(transformerDimensionInput));

          const allResultData: Dimension = {
            dimension: {
              dimensionInput: transformerDimensionInput,
              dimensionResult:
                type === 'Drive'
                  ? {
                      ...response?.data?.dimension?.dimensionResult,
                      supplyDriveLoads: [
                        {
                          ...response?.data?.dimension?.dimensionResult
                            .supplyDriveLoads[0],
                          motorUnit: {
                            ...motorResultData?.dimension?.dimensionResult
                              ?.supplyDriveLoads?.[0].motorUnit,
                          },
                        },
                      ],
                    }
                  : response?.data?.dimension?.dimensionResult,
            },
            exceptionType: response?.data?.exceptionType,
            message: response?.data?.message,
          };
          setDimensioningDataResult(allResultData);
          dispatch(selectDrive(allResultData));

          type !== 'Drive' && dispatch(selectMotor(allResultData));
          dispatch(
            dimensionOutputData(response?.data?.dimension?.dimensionResult),
          );
          let inverterUnitResult =
            response?.data?.dimension?.dimensionResult?.supplyDriveLoads?.[0]
              ?.inverterUnit?.results;
          dispatch(
            productAndTypeCodeSave({
              productCode:
                allResultData?.dimension?.dimensionResult?.supplyDriveLoads?.[0]
                  ?.motorUnit?.results?.[1]?.value ?? null,
              motorTypeDesignation:
                allResultData?.dimension?.dimensionResult?.supplyDriveLoads?.[0]
                  ?.motorUnit?.results?.[0]?.value ?? null,
              driveTypeCode: inverterUnitResult
                ? (inverterUnitResult?.[0]?.value ?? null)
                : '',
            }),
          );
          //update the plus code to empty as the drive changes
          // response?.data?.dimension?.dimensionResult.supplyDriveLoads[0]
          //take the drivetypecodesand then check with response and update the api

          if (inverterUnitResult?.[0]?.value !== drivePlsuCode) {
            dispatch(
              updateProductTypeDriveCode(
                response?.data?.dimension?.dimensionResult?.supplyDriveLoads[0]
                  ?.inverterUnit?.results?.[0]?.value,
              ),
            );
            dispatch(
              setPlusCodeOptionValues({
                longtypeCode:
                  response?.data?.dimension?.dimensionResult
                    ?.supplyDriveLoads[0]?.inverterUnit?.results?.[0]?.value,
                options: [],
              }),
            );
            //check if there is drive then keep the tab opened
            //else close the configure tab
            const filteredTabs = workspace?.applicationActiveTabs?.filter(
              (tab) => tab.value !== 'configurationDrive',
            );
            dispatch(setApplicationActiveTabs(filteredTabs));
            updatePlusCode({
              applicationId: workspace.id,
              updatePlusCodes: {
                longtypeCode:
                  response?.data?.dimension?.dimensionResult
                    ?.supplyDriveLoads[0]?.inverterUnit?.results?.[0]?.value,
                options: [],
              },
            })
              .then((res) => {
                console.log('insdie the response', res);
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            dispatch(
              updateProductTypeDriveCode(
                workspace?.drivePlusCodeObject?.longtypeCode,
              ),
            );
          }
          //else condition
          const motorError =
            response?.data?.dimension?.dimensionResult?.supplyDriveLoads?.[0]
              ?.motorUnit?.motorError;
          const driveError =
            response?.data?.dimension?.dimensionResult?.supplyDriveLoads?.[0]
              ?.inverterUnit?.invError;
          if (!!motorError) {
            dispatch(
              saveMotorErrorResponse({
                errorMotorSelection: motorError,
                isError: motorError.toLowerCase().includes('error'),
                isWarning: !motorError.toLowerCase().includes('error'),
              }),
            );
          } else {
            dispatch(resetMotorErrorResponse());
          }

          if (
            !!driveError &&
            !motorResultData?.dimension?.dimensionResult?.supplyDriveLoads?.[0]
              ?.motorUnit?.results?.[0]?.value
          ) {
            dispatch(
              saveDriveErrorResponse({
                errorDriveSelection: driveError,
                isError: driveError.toLowerCase().includes('error'),
                isWarning: !driveError.toLowerCase().includes('error'),
              }),
            );
          } else {
            dispatch(resetDriveErrorResponse());
          }

          setIsGetDimensionLoading(false);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setIsGetDimensionLoading(false);
          notificationManager.error('Unable to retrieve dimension data');
        }

        if (manuallySelectedIndex !== '') {
        }
      })
      .catch(() => {
        setIsLoading(false);
        setIsGetDimensionLoading(false);
      });
    setSelectDriveClicked(true);
  };

  useEffect(() => {
    if (isSelectDriveClicked) {
      if (dimensioningDataResult?.dimension?.dimensionInput) {
        dispatch(selectDrive(dimensioningDataResult));
        setSelectDriveClicked(false);
      }
      if (isMobile && selectedOption === 'Select Drives') {
        setViewResult(true);
      }
    }
  }, [
    isSelectDriveClicked,
    dispatch,
    dimensioningDataResult,
    isMobile,
    selectedOption,
  ]);

  const handleCatalogueData = (response: any) => {
    if (response !== undefined) {
      setIsLoading(true);
      let results =
        response?.dimension?.dimensionResult?.supplyDriveLoads?.[0]
          ?.inverterUnit?.results;
      let productCode = results ? results?.[1]?.value : undefined;
      let typeDesignation = results ? results?.[0]?.value : undefined;
      let allResultData: Dimension;
      catalogueData({
        productCode:
          productCode && productCode !== '[undefined]' ? productCode : null,
        typeDesignation:
          !productCode && productCode !== '[undefined]' && typeDesignation
            ? typeDesignation
            : '',
        countryCode: null,
        langCode: 'en',
      })
        .then((catalogueResponseDrive) => {
          if ('data' in catalogueResponseDrive) {
            if (
              response?.dimension?.dimensionInput?.selectionInputs?.[0]
                ?.dataCategory === 'All'
            ) {
              let motorTypeDesignation =
                response?.dimension?.dimensionResult?.supplyDriveLoads?.[0]
                  ?.motorUnit?.results?.[0]?.value;

              if (motorTypeDesignation !== null) {
                catalogueData({
                  productCode: undefined,
                  typeDesignation: motorTypeDesignation ?? '',
                  countryCode: null,
                  langCode: 'en',
                })
                  .then((catalogueResponseMotor) => {
                    if ('data' in catalogueResponseMotor) {
                      setIsLoading(true);
                      allResultData = {
                        dimension: {
                          dimensionInput: {
                            ...response?.dimension?.dimensionInput,
                            dutyCycleTime: selectedLoadData.dutyCycleTime,
                            maxLoadTime: selectedLoadData.maxLoadTime,
                          },
                          dimensionResult: response?.dimension?.dimensionResult,
                          catalogueDataMotor: catalogueResponseMotor.data,
                          catalogueDataDrive: catalogueResponseDrive.data,
                        },
                        exceptionType: response?.exceptionType,
                        message: response?.message,
                      };
                      dispatch(selectDrive(allResultData));
                      dispatch(selectMotor(allResultData));
                      setDimensioningDataResult(allResultData);
                      setIsLoading(false);
                    } else {
                      setIsLoading(false);
                      //api fetch call is done, no data is returned
                      setHasCatalogueDataFetched(true);
                    }
                  })
                  .catch(() => {
                    setIsLoading(false);
                    setHasCatalogueDataFetched(false);
                  })
                  .finally(() => {
                    updateProjectApiCall(allResultData);
                  });
              }
              setIsLoading(false);
            } else {
              let allResultData: Dimension = {
                dimension: {
                  dimensionInput: {
                    ...response?.dimension?.dimensionInput,
                    dutyCycleTime: selectedLoadData.dutyCycleTime,
                    maxLoadTime: selectedLoadData.maxLoadTime,
                  },
                  dimensionResult: response?.dimension?.dimensionResult,
                  catalogueDataDrive: catalogueResponseDrive.data,
                },
                exceptionType: response?.exceptionType,
                message: response?.message,
              };
              setDimensioningDataResult(allResultData);
              dispatch(selectDrive(allResultData));
              updateProjectApiCall(allResultData);
              setIsLoading(false);
            }
            setHasCatalogueDataFetched(true);
          } else {
            setIsLoading(false);
            //api fetch call is done, no data is returned
            setHasCatalogueDataFetched(true);
          }
        })
        .catch(() => {
          setIsLoading(false);
          setHasCatalogueDataFetched(false);
        });
    }
  };

  useEffect(() => {
    if (
      hasDimensionDataFetched ||
      dimensioningDataResult ||
      hasRetryCatalogDataSelected
    ) {
      if (
        (dimensioningDataResult?.dimension?.dimensionResult
          ?.supplyDriveLoads?.[0]?.inverterUnit?.results?.[0].value ||
          dimensioningDataResult?.dimension?.dimensionResult
            ?.supplyDriveLoads?.[0]?.motorUnit?.results?.[0].value) &&
        !driveResultData?.dimension?.catalogueDataDrive?.catalogPDFURL &&
        !hasCatalogueDataFetched &&
        !isGetDimensionLoading
      ) {
        handleCatalogueData(dimensioningDataResult);
        setHasCatalogueDataFetched(true);
        dispatch(setHasRetryCatalogDataSelected(false));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [motorResultData, isGetDimensionLoading, hasRetryCatalogDataSelected]);

  const onManualSelectionClose = () => {
    setViewManualSelection(false);
  };

  const getLeftSection = () => {
    let isLeftBtnDisabled =
      isLoading ||
      isManualSelectionLoading ||
      isGetDimensionLoading ||
      selectedLoadData?.iContinuous === '' ||
      selectedLoadData?.iMax === '' ||
      selectedLoadData?.iContinuous === '0' ||
      selectedLoadData?.iMax === '0';
    let isMiddleBtnDisabled =
      motorLoadData?.power === '0' ||
      motorLoadData?.power === 'NaN' ||
      isLoading ||
      isManualSelectionLoading ||
      isGetDimensionLoading;
    let isRightBtnDisabled =
      !driveResultData?.dimension?.dimensionResult?.supplyDriveLoads?.[0]
        ?.inverterUnit?.results?.[0].value ||
      isLoading ||
      isManualSelectionLoading ||
      isGetDimensionLoading;
    return (
      <>
        <DriveSelectionForm
          viewManualSelection={viewManualSelection}
          onManualSelectionClose={onManualSelectionClose}
          isManualSelectionLoading={isManualSelectionLoading}
          onManualSelectClick={handleSelectButtonClick}
          isLoading={isLoading}
        />
        {!isMobile && (
          <ButtonGroup
            isLeftBtnDisabled={isLeftBtnDisabled}
            isMiddleBtnDisabled={isMiddleBtnDisabled}
            isRightBtnDisabled={isRightBtnDisabled}
            leftButtonName={t(I18.drive_selection_select_drive_button)}
            middleButtonName={t(I18.drive_selection_select_all_button)}
            rightButtonName={t(I18.drive_selection_manual_selection_button)}
            onLeftClick={
              isLeftBtnDisabled
                ? undefined
                : () => handleSelectButtonClick('Drive')
            }
            onMiddleClick={
              isMiddleBtnDisabled
                ? undefined
                : () => handleSelectButtonClick('All')
            }
            onRightClick={
              isRightBtnDisabled ? undefined : handleManualSelection
            }
            isSidebarOpen={isSidebarOpen}
          />
        )}
      </>
    );
  };

  const getRightSection = () => {
    return (
      <>
        <DriveSelectionResult isLoading={isLoading} isError={status.isError} />
      </>
    );
  };

  return (
    <>
      <DualSectionLayout
        leftChildren={getLeftSection()}
        rightChildren={getRightSection()}
        isSidebarOpen={isSidebarOpen}
        viewResult={viewResult}
      />
      {isMobile && (
        <ButtonGroupMobile
          isLeftBtnDisabled={false}
          isRightBtnDisabled={false}
          leftButtonName={t(I18.drive_selection_select_button)}
          leftButtonOptions={[
            t(I18.drive_selection_select_drive_button),
            t(I18.drive_selection_select_all_button),
            t(I18.drive_selection_manual_selection_button),
          ]}
          rightButtonName={t(I18.drive_selection_result_button)}
          onBackClick={handleBackButtonClick}
          onResultClick={handleSelectDriveButtonClick}
        />
      )}
    </>
  );
};

export default DriveSelection;
